import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import contact from "../assets/img/contact.png";
import download from "../assets/img/WhitePaper.png";
import audit from "../assets/img/AuditReport.png";
// import logo_pic from "../assets/img/logo.png"
import logo_pic from "../assets/img/cvt logo.png";
import currency_1 from "../assets/img/Group 1000005551.png";
import Sec01 from "../assets/img/Ellipse 2 (1).png";
import icons_1 from "../assets/img/Airdrop_icon.png";
import icons_2 from "../assets/img/Trading fee.png";
import icons_3 from "../assets/img/Patnership_icon.png";
import macbook from "../assets/img/Macbook_mockup.png";
import sec02 from "../assets/img/download_cv.png";
import sec03 from "../assets/img/bg-gradient-img (1).png";
import sec04 from "../assets/img/Rectangle 29 .png";
import coloring from "../assets/img/Group 1000005552.png";
import currency from "../assets/img/Cvtoken_Coin.png";
import sec05 from "../assets/img/Eclipse (1).png";
import { NavLink } from "react-router-dom";
import sli_1 from "../assets/img/airdrop-box.png";
import money from "../assets/img/earning.png";
import hand from "../assets/img/partner.png";
import market from "../assets/img/market.png";

const Home = () => {
  return (
    <>
      {/* cv_webiste ready done by madhukar */}

      {/* 17-9-2024 link lgaya hu and design changes mein design thik kiya hu done by madhukar  */}
      {/* 
            <header className=''>
                <nav className="navbar navbar-expand-md first-nav">
                    <div className="container-fluid">
                        <a className="navbar-logo" href="#">
                            <img src={logo_pic} alt="cv_token" style={{ height: 44 }} />
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto text-white mb-1 mb-lg-0 gap-1">
                                <li className="nav-item">
                                    <a className="nav-link text-white" aria-current="page" href="#home">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#about">About</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#token">Tokenomics</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-white" href="#contact">Contact Us</a>
                                </li>
                            </ul>
                            <div className="d-flex gap-2">
                                <button className="btn btn-outline-in text-white" type="button">Airdrop</button>
                                <a href='https://cvtoken.us/stake/Dashboard/User/MainLogin' target="_blank" className='cust-link'><button className="btn btn-outline-in text-white" type="button">Sign in</button></a>
                                <a href='https://cvtoken.us/stake/Dashboard/Register' target="_blank" className='custom-link'><button className="btn btn-up" type="button">Sign up</button> </a>
                            </div>
                        </div>
                    </div>
                </nav>
            </header> */}

      <section className="cvt_0" id="">
        <header className="">
          <nav className="navbar navbar-expand-md first-nav">
            <div className="container-fluid">
              <a className="navbar-logo" href="#">
                <img src={logo_pic} alt="cv_token" style={{ height: 44 }} />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto text-white mb-1 mb-lg-0 gap-1">
                  <li className="nav-item">
                    <a
                      className="nav-link text-white"
                      aria-current="page"
                      href="#home"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-white" href="#about">
                      About
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-white" href="#token">
                      Tokenomics
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link text-white" href="#contact">
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className="d-flex gap-2">
                  <button
                    className="btn btn-outline-in text-white"
                    type="button"
                  >
                    Airdrop
                  </button>
                  <a href="https://cvtoken.online/login" className="cust-link">
                    <button
                      className="btn btn-outline-in text-white"
                      type="button"
                    >
                      Sign in
                    </button>
                  </a>
                  <a
                    href="https://cvtoken.online/signup"
                    className="custom-link"
                  >
                    <button className="btn btn-up" type="button">
                      Sign up
                    </button>{" "}
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div className="jkh"></div>
        <div className="">
          <img className="sec01_i" src={Sec01} alt="" />
        </div>
        <div className="container jhu">
          <Swiper
            spaceBetween={30}
            modules={[Autoplay]}
            effect={"fade"}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="row contact py-5">
                <div className="col-lg-7 col-md-10 align-self mt-5 cry">
                  <div className="fuel_qw mt-5">
                    <h4 className="w fuel_titles">
                      Fuel Your Crypto Future with{" "}
                      <span className="text_co">CVT</span>
                    </h4>
                    <p className="w para_content mt-3">
                      Unlock unique rewards, increase earnings, and
                      <br />
                      elevate your CVT experience
                    </p>
                  </div>
                  <div className="button mt-5">
                    <a
                      href="https://cvtoken.us/stake/Dashboard/Register"
                      className="custom-link"
                      target="_blank"
                    >
                      <button type="button" class="btn btn-sec">
                        Sign Up Now
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-lg-5 col-md-10 photo">
                  <img className="download_img" src={currency_1} />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="row contact py-5">
                <div className="col-lg-7 col-md-10 align-self mt-5">
                  <div className="fuel_qw mt-5">
                    <h4 className="w fuel_titles_02">
                      CVT Airdrop <span className="text_co">5 Lakh</span> Token
                      up for grab
                    </h4>
                    <p className="w para_content mt-3">
                      CvToken is excited to announce a new airdrop campaign,
                      offering a generous reward to our community. By
                      participating in this event, you have the opportunity to
                      receive a significant amount of CvToken at no cost.
                    </p>
                  </div>
                  <div className="button mt-5">
                    <button type="button" class="btn btn-sec">
                      Create Airdrop Account
                    </button>
                  </div>
                </div>
                <div className="col-lg-5 col-md-10 photo">
                  <img className="download_img" src={sli_1} />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="row contact py-5">
                <div className="col-lg-7 col-md-10 align-self mt-5">
                  <div className="fuel_qw mt-5">
                    <h4 className="w fuel_titles_03">
                      Partner with CVtrade{" "}
                      <span className="text_co">Earn 49% Profit</span>
                    </h4>
                    <p className="w para_content mt-3">
                      Invest $1000 and secure up to 49% commission on exchange
                      profits. This limited-time offer is your gateway to
                      exclusive earnings in the growing crypto market.
                    </p>
                  </div>
                  <div className="button mt-5">
                    <button type="button" class="btn btn-sec">
                      Join Us Now
                    </button>
                  </div>
                </div>
                <div className="col-lg-5 col-md-10 photo">
                  <img className="download_img" src={hand} />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="row contact py-5">
                <div className="col-lg-7 col-md-10 align-self mt-5">
                  <div className="fuel_qw mt-5">
                    <h4 className="w fuel_titles_04">
                      Complete your tasks and{" "}
                      <span className="text_co">Earn $2</span>
                    </h4>
                    <p className="w para_content mt-3">
                      Earn $1 more referral upto 3 levels of earnings $0.50,
                      $0.30 and $0.20
                    </p>
                  </div>
                  <div className="button mt-5">
                    <button type="button" class="btn btn-sec">
                      Get Started
                    </button>
                  </div>
                </div>
                <div className="col-lg-5 col-md-10 photo">
                  <img className="download_img" src={money} />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      <section section className="cvt_0_1" id="feature">
        <div className="container">
          <div className="">
            <div className="qwe_data">
              <h4 className="w fgku">
                Features & Functions of <span className="text_co">CVT</span>{" "}
              </h4>
              <p className="w khjbj">
                CV Tokens enable users to buy and sell virtual products and
                services in the Metaverse. With a vibrant e-commerce system,
                users can purchase virtual clothes, accessories, and other
                digital items to personalize their avatars, enhancing their
                virtual reality experiences.
              </p>
            </div>
            <div className="">
              <div class="row mt-5 g-3">
                <div class="col-lg-4">
                  <div class="card shadow-sm">
                    <div className="icons mt-3">
                      <img className="icons_img" src={icons_1} />
                    </div>
                    <div class="card-body">
                      <h5 class="card-text">Airdrop Opportunity </h5>
                      <p class="card-text_1">
                        Claim 1.5 Lakh CVT airdrop and jumpstart your crypto
                        journey with free tokens!
                      </p>
                      <span className="strat">
                        Get Started Now <i class="bi bi-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card shadow-sm">
                    <div className="icons mt-3">
                      <img className="icons_img" src={icons_3} />
                    </div>
                    <div class="card-body">
                      <h5 class="card-text">Partnership Offer </h5>
                      <p class="card-text_1">
                        Partner with CVtrade by investing $1000 and enjoy up to
                        49% exchange commission.
                      </p>
                      <span className="strat">
                        Get Started Now <i class="bi bi-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card shadow-sm">
                    <div className="icons mt-3">
                      <img className="icons_img" src={icons_2} />
                    </div>
                    <div class="card-body">
                      <h5 class="card-text">Reduced Trading Fee</h5>
                      <p class="card-text_1">
                        Unlock exclusive trading fee discounts, maximising
                        profits on every transaction.{" "}
                      </p>
                      <span className="strat">
                        Get Started Now <i class="bi bi-arrow-right"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ct_sec" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="ret mt-4">
                <h5 className="w earning_cv">
                  About our <span className="text_co">Earning Platform</span>
                </h5>
                <p className="w earing_cont mt-3">
                  CVTrade is a platform designed to empower you in the exciting
                  realm of cryptocurrencies.
                </p>
              </div>
              <div className="ret_another mt-5">
                <p className="w ano">
                  Refreaal : Stay informed with real-time market data and
                  insights.{" "}
                </p>
                <p className="w ano">
                  Airdrop : Explore various trading possibilities replace with a
                  more generic term if specific trading options aren't mentioned
                  on your website
                </p>
                <p className="w ano">
                  Security Focus : If security is mentioned on your website Rest
                  assured, security is a top priority at CVTrade.io.
                </p>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="lapt">
                <img src={macbook} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ct_road">
        <div className="ct_rfgb">
          <div className="platform-road">
            <h3 className="w road">
              <span className="text_co"> Platform </span> Roadmap
            </h3>
          </div>
          <div class="timeline mt-5">
            <div class="container-road left">
              <div class="content">
                <h2 className="op_po mt-2">
                  {" "}
                  <span className="text_mlo">Pre Launch </span> (2023)
                </h2>
                <p>
                  <span>• Product/software development.</span>
                  <span>• Smart contract integration. </span>
                  <span>• Marketing & promotion. </span>
                </p>
              </div>
            </div>
            <div class="container-road right">
              <div class="content">
                <h2 className="op_po mt-2">
                  <span className="text_mlo">Launch</span> (2024)
                </h2>
                <p>
                  <span>• Launch on own Exchange. </span>
                  <span>• International Corporate office establishment.</span>
                  <span>• First international event.</span>
                  <span>• World wide formation of Advisory Committee.</span>
                </p>
              </div>
            </div>
            <div class="container-road left">
              <div class="content">
                <h2 className="op_po mt-2">
                  <span className="text_mlo">Accelerator</span> (2025)
                </h2>
                <p>
                  <span>• International meet of New Bilinearis. .</span>
                  <span>• Launching own Blockchain. </span>
                  <span>• Product enhancement.</span>
                  <span>
                    • World wide selection of 20 Directors cum Investors from
                    each country
                  </span>
                </p>
              </div>
            </div>
            <div class="container-road right">
              <div class="content">
                <h2 className="op_po mt-2">
                  {" "}
                  <span className="text_mlo">Booster</span> (2026)
                </h2>
                <p>
                  <span>• Working on Utilization of token.</span>
                  <span>• Generation of Revenue by Boat API Integration.</span>
                  <span>• International meet of New Bilinearis.</span>
                  <span>
                    • Creation of Smart Contract with all directors cum
                    Investors.
                  </span>
                </p>
              </div>
            </div>
            <div class="container-road left">
              <div class="content">
                <h2 className="op_po mt-2">
                  {" "}
                  <span className="text_mlo">Initiation</span> (2027)
                </h2>
                <p>
                  <span>• Utilization in Forex market.</span>
                  <span>• World corporate meet for future Investments. </span>
                  <span>• International meet of New Bilinearis.</span>
                </p>
              </div>
            </div>
            <div class="container-road right">
              <div class="content">
                <h2 className="op_po mt-2">
                  <span className="text_mlo">Relish</span> (2028)
                </h2>
                <p>
                  <span>• Investment in new technology.</span>
                  <span>• International meet of New Bilinearis.</span>
                  <span>
                    • Distribution of 2% profit among 1 million top holders
                    excluding directors cum Investor.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div section className="download_cv">
        <div className="container mt-3 mb-5">
          <div className="row contact">
            <div className="col-lg-7 col-md-12 align-self mt-5">
              <div className="col_2 mt-4">
                <h4 className="w title_heading">
                  Download the CVT <span className="text_co">Whitepaper</span>{" "}
                </h4>
                <p className="w content01 mt-3">
                  Explore the full details of CVT, including its tokenomics,
                  platform integration, and future roadmap. Download our
                  whitepaper to learn more about how CVT is reshaping
                  decentralized finance.
                </p>
              </div>
              {/* <div className="button mt-5">
                                <a href='https://cvtoken.us/uploads/CVTwhitepaper_0.2.pdf' className='btn-secon-link-z' target="_blank" >
                                    <button type="button" class="btn btn-secon">Download Now</button></a>
                            </div> */}

              {/* <div className="button mt-5">
                                <a href='https://cvtoken.us/uploads/CVTwhitepaper_0.2.pdf' className='btn-secon-link-z' target="_blank" >
                                    <button type="button" class="btn btn-secon">Download Now</button>
                                </a>
                            </div> */}

              <div className="button mt-5">
                <a
                  href="https://cvtoken.us/uploads/CVTwhitepaper_0.2.pdf"
                  className="btn-secon-link-z btn btn-secon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Now
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 py-3">
              <div className="mix">
                <div className="download_des_cv_01"></div>
                <div className="download_des_cv_02"></div>
              </div>
              <img className="download_img" src={download} />
            </div>
          </div>
        </div>
      </div>

      <section className="cvt">
        <div className="container">
          <div className="row contact">
            <div className="col-lg-6 col-md-10 py-3">
              <img className="audit_img" src={audit} />
              <div className="vbey">
                <div className="vbey_1"></div>
                <div className="vbey_2"></div>
              </div>
            </div>

            <div className="col-lg-6 col-md-10 align-self mt-5">
              <div className="scope">
                <div className="scope">
                  <button type="button" class=" w btn btn-scope">
                    Verified by <span className="text_cop">Cyber Scope</span>
                  </button>
                </div>
              </div>
              <div className="col_3 mt-4">
                <h3 className="w acccess_title">
                  Access the CVT <span className="text_co">Audit Report</span>
                </h3>
                <p className="w access_content">
                  Review the comprehensive security audit of CVT, ensuring
                  transparency and trust in our smart contracts and platform
                  operations. Download the report for full insights.
                </p>
              </div>
              <div className="button mt-5">
                <a
                  href="https://www.cyberscope.io/audits/3-cvt"
                  className="btn-secon-link-z"
                  target="_blank"
                >
                  <button type="button" class="btn btn-secon">
                    Download Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="view_cvt" id="token">
        <div className="container mb-5">
          <div className="hfv">
            <h5 className="w cv">
              View CVT <br /> <span className="text_co">Token Allocation</span>
            </h5>
            <p className="w trw">
              Understand the distribution of CVT across key areas, including
              platform growth, rewards, and development. Explore the allocation
              details to see how CVT supports a sustainable ecosystem.
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-xl-3 col-md-12 col-sm-12 mt-5">
              <img className="coloring_img" src={coloring} />
            </div>
            <div className="col-xl-3 col-md-4 col-sm-12 mt-5">
              <div className="kjk">
                <button type="button" class="btn btn-supply">
                  <h6 className="maximum_alpha">Maximum Supply</h6>
                  <h6 className="maximum_numb">50,000,000</h6>
                </button>
              </div>
              <div className="adug d-flex mt-5">
                <div className="point_01"></div>
                <div className="innert">
                  <h6 className="w pre">Pre Sale</h6>
                  <span className="w pre01">50%</span>
                </div>
              </div>
              <div className="adug d-flex mt-3 ">
                <div className="point_02"></div>
                <div className="innert">
                  <h6 className="w pre">Exchange</h6>
                  <span className="w pre01">25%</span>
                </div>
              </div>
              <div className="adug d-flex mt-3 ">
                <div className="point_03"></div>
                <div className="innert">
                  <h6 className="w pre">Founding Team</h6>
                  <span className="w pre01">10%</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-12 mt-5">
              <div className="kjk">
                <button type="button" class="btn btn-supply">
                  <h6 className="maximum_alpha">Total Supply</h6>
                  <h6 className="maximum_numb">20,000,000</h6>
                </button>
              </div>
              <div className="adug d-flex mt-5">
                <div className="point_04"></div>
                <div className="innert">
                  <h6 className="w pre">Angel Investor</h6>
                  <span className="w pre01">10%</span>
                </div>
              </div>
              <div className="adug d-flex mt-3">
                <div className="point_05"></div>
                <div className="innert">
                  <h6 className="w pre">Reward / Airdrop</h6>
                  <span className="w pre01">5%</span>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-4 col-sm-12 mt-5">
              <div className="kjk">
                <button type="button" class="btn btn-supply">
                  <h6 className="maximum_alpha">Pre Sale</h6>
                  <h6 className="maximum_numb">2,500,000</h6>
                </button>
              </div>
              <div>
                <img className="mec001_i" src={sec05} alt="" />
              </div>
              <div className="pre_img">
                <img className="pre_sale" src={currency} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="frequently-asked">
        <div className="container mb-5">
          <div className="Frequently mt-2 mb-5">
            <h2 className="w">Frequently Asked Questions</h2>
          </div>
          <div class="accordion mt-4" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What is CV Token?
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  {" "}
                  CVToken (CVT) appears to be the native digital currency
                  associated with the platform CVToken.us. Here's what we can
                  glean based on publicly available information:
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  On sorceress we are based?
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  {" "}
                  Unlock the Power of CVTs: Now Integrated Register on
                  CVToken.us and seamlessly manage your CVTs using the secure
                  and efficient Binance Smart Chain network.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  What Are The Advantages of CVToken?
                </button>
              </h2>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  {" "}
                  Potential Platform-Specific Advantages: Platform Utility: CVTs
                  might be essential for using certain features or
                  functionalities within the CVToken.us platform. This could
                  include anything from paying transaction fees to accessing
                  premium services. Governance Rights (if applicable): In some
                  cases, holding CVTs might grant voting rights or influence on
                  the future development of the CVToken.us platform. General
                  Cryptocurrency Advantages: Potential for Appreciation: Like
                  most cryptocurrencies, CVT
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  How we can earn on CVToken Project?
                </button>
              </h2>
              <div
                id="flush-collapseFour"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  CVToken.us Website: The official website is likely the most
                  reliable source for information about potential earning
                  methods with CVTs. Look for sections about staking, liquidity
                  pools, or other programs that might incentivize user
                  participation. Community Forums: Search online forums or
                  communities related to CVTokens to see how other users are
                  potentially earning CVTs. However, be cautious about the
                  credibility of information.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  We Are Listed On?
                </button>
              </h2>
              <div
                id="flush-collapseFive"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  CVTs: Now Traded on Multiple Exchanges! Expand your trading
                  horizons! CVTokens (CVTs) are now available on a wider range
                  of exchanges, offering you greater flexibility and convenience
                  when buying, selling, or managing your CVTs. This intro
                  emphasizes these key points: Increased Accessibility: CVTs are
                  now tradable on multiple platforms. Benefits for Users: Users
                  have more options for managing their CVTs. Actionable:
                  Encourages users to explore the new trading opportunities.
                  Here are some ways
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="sec001_i" src={sec04} alt="" />
      </section>

      <section className="answr-home" id="contact">
        <div className="container">
          <div className="row contact">
            <div className="col-lg-6 col-md-10 align-self">
              <div className="col_1">
                <h4 className="w title_contact mt-3">Contact Us</h4>
                <p className="w heading_outfit mt-4 mb-4">
                  Have questions or need more information? Reach out to our team
                  for support and inquiries. We’re here to help with all things
                  CVT
                </p>
              </div>
              <form className="mt-5">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control input_form"
                    placeholder="First Name"
                    id="exampleInputEmail1"
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control input_form"
                    placeholder="Email Address"
                    id="exampleInputEmail1"
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control input_form"
                    placeholder="Message"
                    id="floatingTextarea2"
                    style={{ height: 150 }}
                    defaultValue={""}
                  />
                </div>
                <div className="mt-5 gap-3">
                  <button type="button" class="btn btn-second">
                    Submit
                  </button>
                  <button type="button" class=" w btn btn-scope_01">
                    info@cvtoken.us
                  </button>
                  <button type="button" class=" w btn btn-scope_01">
                    Support@cvtoken.us
                  </button>
                </div>
              </form>
            </div>

            <div className="col-lg-6 col-md-10 text-center py-3">
              <img className="contact_img" src={contact} />
              <div className="downl">
                <div className="downl_des_cv_01"></div>
                <div className="downl_des_cv_02"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer_hbj">
        <div class="footer">
          <div class="footer-inner">
            <div class="row ljo">
              <div class="col-md-3">
                <div class="logo">
                  <img
                    alt="Company Logo"
                    src={logo_pic}
                    height="50"
                    width="150"
                  />
                </div>
                <div class="description">
                  Welcome to our trading site! We offer the best, most
                  affordable products and services around. Shop now and start
                  finding great deals!
                </div>
              </div>
              <div class="col-md-2 quick-links">
                <div class="links">
                  <h5>QUICK LINKS</h5>
                  <ul>
                    <li>
                      <a href="#about">About Us</a>
                    </li>
                    <li>
                      <a href="#">Services</a>
                    </li>
                    <li>
                      <a href="#feature">Feature</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-2 support-links">
                <div class="links">
                  <h5>SUPPORT</h5>
                  <ul>
                    <li>
                      <a href="#">Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="#">FAQ</a>
                    </li>
                    <li>
                      <a href="#">Support Center</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-2 company-links">
                <div class="links">
                  <h5>COMPANY</h5>
                  <ul>
                    <li>
                      <a href="#">Careers</a>
                    </li>
                    <li>
                      <a href="#">Updates</a>
                    </li>
                    <li>
                      <a href="#">Jobs</a>
                    </li>
                    <li>
                      <a href="#">Announce</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="social-icons">
              <div className="socl">
                <a
                  href="https://www.facebook.com/people/CVToken/61558552580035/"
                  target="_blank"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
                <a
                  href="https://www.instagram.com/_cvtokenofficial/"
                  target="_blank"
                >
                  <i class="fab fa-instagram"></i>
                </a>

                <a
                  href="https://www.linkedin.com/in/cv-token-08a18a327/"
                  target="_blank"
                >
                  <i class="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://youtube.com/@CRYPTOVENTURE-hu8cu?si=W8Hzyp3HMgHfJmRB"
                  target="_blank"
                >
                  <i class="fab fa-youtube"></i>
                </a>
                {/* <a href="https://twitter.com/CryptoV75575" target="_blank">
                                    <i class="fab fa-twitter">
                                    </i>
                                </a> */}
                {/* <a href="https://www.reddit.com/user/OkComputer5578/comments/" target="_blank">
                                    <i class="fab fa-reddit">
                                    </i>
                                </a> */}
                <a
                  href="https://www.quora.com/profile/CRYPTO-VENTURE-3"
                  target="_blank"
                >
                  <i class="fab fa-quora"></i>
                </a>
                <a href="https://medium.com/@Cvtoken" target="_blank">
                  <i class="fab fa-medium"></i>
                </a>
                <a
                  href="https://x.com/i/flow/login?redirect_after_login=%2FCryptoV75575"
                  target="_blank"
                >
                  <i class="fab fa-x"></i>
                </a>
                {/* <a href="https://coinmarketcap.com/currencies/monsta-xrp/" target="_blank" >
                                    <img className={market}>
                                    </img>
                                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
